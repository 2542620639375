import React, { useState } from 'react';
import { data } from "../data/data.js";
import HTML from '../assets/html.png';
import { Badge } from "./ui/badge.jsx"
import { useContext } from 'react';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./ui/carousel.jsx"
import { useMobile } from '../context/MobileContext.jsx';




export default function Work() {
  // const { isMobile } = useContext(MobileContext)

  const { isMobile } = useMobile();




  return (
    <div name='work' className='w-full h-full bg-[#19181b] text-gray-300 items-center pt-20 pb-20 pr-10 pl-10'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
      <div className='sm:text-right pb-8 pl-4'>
        <p className='text-4xl font-bold inline border-b-4 border-custom-cyan sm:text-right pl-4'>
          My Work
        </p>
        </div>
        {isMobile && 
      <div className=' xs:w-1/2 sm:w-1/2 md:w-3/4 lg:w-3/4 xl:w-3/4 2xl:w-1/2 bg-white h-full rounded-2xl px-4'>
      <Carousel>
        <CarouselContent>
          {data.map((item, index) => (
            <CarouselItem key={index} className="w-3/4 flex justify-center">
              <div className="">
                <div className="">
                <h1 className="xl:text-6xl lg:text-4xl md:text-3xl sm:text-2xl text-xs font-bold mb-2 text-center text-black">
     <a href={item.url} target="_blank" rel="noopener noreferrer">
     {/* {item.name} */}
     </a>
     </h1>
     <p className="text-black xl:text-lg lg:text-md md:text-base sm:text-sm text-xs">
     {/* {item.description} */}
     </p>
     
  
     
                </div>
                <div className='flex justify-center items-center w-full'>
                  <div className="flex justify-center items-center h-full">
                  <a href={item.url} target="blank">
                  <img
                      src={item.image}
                      alt={item.name}
                      className="max-h-500 object-cover"
                    >
                     {/* <a href={item.url} target="blank"></a> */}
                    
                    </img>
                    </a>
                  </div>
                </div>
                <h1 className="xl:text-6xl lg:text-4xl md:text-3xl sm:text-2xl text-2xl font-bold mb-2 text-center text-black pt-3">
<a href={item.url} target="_blank" rel="noopener noreferrer" className='text-black'>
{item.name}
</a>
</h1>
<p className="text-black xl:text-lg lg:text-md md:text-base sm:text-sm text-sm">
{item.description}
</p>
                <div className="grid grid-cols-3 gap-2 text-center py-8 pt-8 pb-5">
     {item.techStack.map((tech, index) => (
     <Badge
     key={index}
     className="bg-white !text-black shadow-sm shadow-[#040c16] hover:scale-110 duration-500"
     variant="outline"
     >
     {tech}
     </Badge>
     ))}
     </div>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
     </div>
        
        }
        {!isMobile &&
 <div className=' sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4 2xl:w-1/2 bg-white h-full rounded-2xl px-4'>
 <Carousel>
   <CarouselContent>
     {data.map((item, index) => (
       <CarouselItem key={index} className="w-3/4 flex justify-center">
         <div className="flex justify-between items-center w-6/7 pt-20">
           <div className="w-1/2 p-8">
           <h1 className="xl:text-6xl lg:text-5xl md:text-4xl sm:text-2xl text-xl font-bold mb-2 text-center text-black">
<a href={item.url} target="_blank" rel="noopener noreferrer">
{item.name}
</a>
</h1>
<p className="text-black xl:text-lg lg:text-md md:text-base sm:text-sm text-xs">
{item.description}
</p>

<div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 text-center py-8">
{item.techStack.map((tech, index) => (
<Badge
key={index}
className="bg-white shadow-sm shadow-[#040c16] hover:scale-110 duration-500"
variant="outline"
>
{tech}
</Badge>
))}
</div>

           </div>
           <div className='flex justify-center items-center w-1/2'>
             <div className="flex justify-center items-center h-full">
             <a href={item.url} target="blank">
             <img
                 src={item.image}
                 alt={item.name}
                 className="max-h-500 object-cover"
               >
                {/* <a href={item.url} target="blank"></a> */}
               
               </img>
               </a>
             </div>
           </div>
         </div>
       </CarouselItem>
     ))}
   </CarouselContent>
   <CarouselPrevious />
   <CarouselNext />
 </Carousel>
</div>
        }
       
      </div>
    </div>
  );
  
  
}
