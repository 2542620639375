import About from "./components/About";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Skills from "./components/Skills";
import Work from "./components/Work";
import Router from 'react-router'
import { createContext, useState } from "react";
import { MobileProvider } from "./context/MobileContext";

// export const MobileContext = createContext();

function App() {
  // const [isMobile, setIsMobile] = useState(false);
  // const value = {isMobile, setIsMobile}

  return (
    <div>
      {/* <MobileProvider > */}
      <Navbar />
      <Home />
      <About />
      <Work />
      <Skills />
      <Contact />
      {/* </MobileProvider> */}
    </div>
  );
}

export default App;
