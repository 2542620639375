import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { MobileProvider } from './context/MobileContext';

ReactDOM.render(
  <MobileProvider>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </MobileProvider>,
  document.getElementById('root')
);


