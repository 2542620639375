import React from 'react';

const About = () => {
  return (
    <div name='about' className='w-full h-full bg-[#19181b] text-gray-300 pt-20 pb-20'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
          <div className='sm:text-right pb-8 pl-4'>
            <p className='text-4xl font-bold inline border-b-4 border-custom-cyan'>
              About Me
            </p>
          </div>
          <div></div>
          </div>
          <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
            <div className='sm:text-right text-4xl font-bold'>
              <p>Hi, my name is Caleb Belkin.</p>
            </div>
            <div>
              <p>My coding journey began in middle school when I found the thrill of changing website titles and content using Chrome's inspect element feature, naively thinking this was all it took to change a website. I soon moved on to creating simple sites with HTML and a bit of CSS. Today, 
                I'm passionate about building high-quality software that improves people's lives. What I enjoy most about programming is teaming up with like-minded individuals to create products that users will love. I specialize in making software for a diverse audience and 
                work with a core tech stack that includes React, Node.js, TypeScript, Svelte, and React Native. 
                I am always eager to learn new technologies.
</p>  
            </div>
          </div>
      </div>
    </div>
  );
};

export default About;
