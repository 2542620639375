import React from 'react';

import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import ReactImg from '../assets/react.png';
import Node from '../assets/node.png';
import FireBase from '../assets/firebase.png';
import GitHub from '../assets/github.png';
import Tailwind from '../assets/tailwind.png';
import Mongo from '../assets/mongo.png';
import Typescript from '../assets/Typescript_logo_2020.svg.png';
import Svelte from '../assets/498px-Svelte_Logo.svg.png'
import PostGres from '../assets/PostgreSQL-Logo.wine.svg'
import Jest from '../assets/jest.svg'
import { techStack } from "../data/data.js";
import TechCard from './TechCard';
import { useState } from 'react';
import { useMobile } from '../context/MobileContext.jsx';


const renderTech = techStack.map((tech, index) => {

    return <TechCard key={index} title={tech.title} image={tech.image} />;
  });


const Skills = () => {
  const { isMobile } = useMobile();
  return ( 
    <div name='skills' className='w-full h-screen bg-[#19181b] text-white pt-20 pb-20'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
          <div className='pt-8'>
              <p className='text-4xl font-bold text-gray inline border-b-4 border-custom-cyan '>Technical Skills</p>
              <p className='py-4'>// A few of my favorite technologies I've worked with</p>
          </div>
          {isMobile && 
          <div className='w-full grid grid-cols-5 sm:grid-cols-6 md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-4 gap-4 text-center py-8'>
          {renderTech}
    </div>
          }
          {!isMobile && 
          <div className='w-full grid grid-cols-2 sm:grid-cols-6 md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-4 gap-4 text-center py-8'>
          {renderTech}
    </div>

          }
          </div>
          </div>
  );
};

export default Skills;
