import WorkIm from '../assets/projects/workImg.jpeg'
import RealEst from '../assets/projects/realestate.jpg'
import Stocks from 'src/assets/Stocks.jpg'
import Auda from 'src/assets/Auda_recording_.gif'
import Svis from 'src/assets/Svis Update.gif'
import Coolcards from 'src/assets/Flashcard.png'
import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import ReactImg from '../assets/react.png';
import Node from '../assets/node.png';
import FireBase from '../assets/firebase.png';
import GitHub from '../assets/github.png';
import Tailwind from '../assets/tailwind.png';
import Mongo from '../assets/mongo.png';
import Typescript from '../assets/Typescript_logo_2020.svg.png';
import AWS from '../assets/AWS1.png'
import Svelte from '../assets/498px-Svelte_Logo.svg.png'
import PostGres from '../assets/PostgreSQL-Logo.wine1.png'
import Jest from '../assets/jest.svg'
import Mocha from '../assets/Mocha_logo.svg.png'
import Next from '../assets/nextjs-icon.svg'
import AUDIA from '../assets/AUDIA (1).png'
import AudiaLogo from '../assets/auda-logo-with-text-v1.png'
import AudiaSingle from '../assets/Audia-single.png'
import SvisLogo from '../assets/SvisLogo.png'
import CLLogo from '../assets/Screen Shot 2024-07-05 at 11.43.47 AM.png'

export const data = [
    {
        id: 1,  // Auda is now the first item
        name: "Audia",
        image: AUDIA,
        image2: AudiaSingle,
        logo: AudiaLogo,
        url: 'https://www.getaudia.com/',
        description: 'Audia is a social media peer to peer music sharing app that allows users to post and share music and playlist with their friends',
        github: "",
        live: "",
        techStack: ['React Native', 'Node', 'Express', 'TypeScript', 'Expo Router', 'Firebase'],
    },
    {
        id: 2,  // Svisualize is now the second item
        name: "Svisualize",
        image: Svis,
        image2: Svis,
        logo: SvisLogo,
        url: 'https://svisualize.dev/',
        description: 'VS Code extension that visualizes Svelte components into a hierarchical tree, features include choosing root file and clickable nodes that take you to the requested file',
        github: "",
        live: "",
        techStack: ['Svelte', 'Node', 'D3.js', 'TypeScript', 'Webpack', 'Mocha'],
    },
    {
        id: 3,  // Cool Cards is now the fourth item
        name: "Cool Cards",
        image: Coolcards,
        image2: Coolcards,
        logo: CLLogo,
        url: 'https://github.com/Hazard-Spaghetti/Flashcard-App', 
        description: 'Interactive flashcard app that lets users create questions and answers to use as a study tool',
        github: "",
        live: "",
        techStack: ['React', 'Node', 'Express', 'MongoDb', 'Redux'],
    }
];

export const techStack = [
 
    { title: 'TYPESCRIPT', image: Typescript },
    { title: 'JAVASCRIPT', image: JavaScript },
    { title: 'REACT', image: ReactImg },
    { title: 'NODE JS', image: Node },
    { title: 'HTML', image: HTML },
    { title: 'CSS', image: CSS },
    { title: 'GITHUB', image: GitHub },
    { title: 'TAILWIND', image: Tailwind },
    { title: 'MONGO DB', image: Mongo },
    { title: 'NEXT', image: Next },
    { title: 'AWS', image: AWS },
    { title: 'FIREBASE', image: FireBase },
    { title: 'POSTGRES', image: PostGres },
    { title: 'SVELTE', image: Svelte },
    { title: 'JEST', image: Jest },
    { title: 'MOCHA', image: Mocha },
  ];
