import React, { createContext, useContext, useState, useEffect } from 'react';

const MobileContext = createContext(undefined);

export const useMobile = () => {
  const context = useContext(MobileContext);
  if (!context) {
    throw new Error('useMobile must be used within a MobileProvider');
  }
  return context;
};

export const MobileProvider = ({ children }) => {
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 920)
    }

    useEffect(() =>{
      window.addEventListener('resize', handleResize);
      handleResize();

      return() => {
        window.removeEventListener('resize', handleResize)
      }

    }, [])


return (
  <MobileContext.Provider value={{ isMobile }}>
  {children}
</MobileContext.Provider>
)
}