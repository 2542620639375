import * as React from "react"
import { cva } from "class-variance-authority";

import { cn } from "../lib/utils.js"

import { useMobile } from "../../context/MobileContext.jsx";




const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outline: "text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)




function Badge({
  className,
  variant,
  ...props
}) {
  const { isMobile } = useMobile();
  const textSizeClass = isMobile ? "text-xxs" : "text-sm";
  // const textColorClass = "text-black";


  return (<div className={cn(badgeVariants({ variant }), textSizeClass, className)} {...props} />);
}

export { Badge, badgeVariants }
