import React from 'react';
import { useMobile } from '../context/MobileContext';

const TechCard = ({ title, image }) => {
  const { isMobile } = useMobile();

  const textSizeClass = isMobile ? 'text-xxs' : null ;

  return (

    <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 ">
      <img className="w-20 mx-auto" src={image} alt={`${title} icon`} />
      <p className={`my-4 ${textSizeClass}`}>{title}</p>
    </div>
  );
};

export default TechCard;


